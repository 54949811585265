export const useTypeNotificationIsForMoneyMovement = (typeNotification?: string) => {
  return (
    typeNotification?.includes('BillCacheBackNotification') ||
    typeNotification?.includes('NewWithdrawalNotificationToAgent') ||
    typeNotification?.includes('NewDepositNotificationToAgent')
  )
}
export const useTypeNotificationIsForClient = (typeNotification?: string) => {
  return (
    typeNotification?.includes('BillAddedToAgentNotification') ||
    typeNotification?.includes('BillStateChangedNotification') ||
    typeNotification?.includes('BillDeliveringCodeToAgentNotification') ||
    typeNotification?.includes('ShippingCostSetToBillToAgentNotification') ||
    typeNotification?.includes('BillDetailStateChangedNotification') ||
    typeNotification?.includes('BillPaymentStateChangedNotification')
  )
}
export const useTypeNotificationIsCustomForAgent = (typeNotification?: string) => {
  return (
    typeNotification?.includes('CustomNotificationForAgent')
  )
}
