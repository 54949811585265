<template>
  <MyAccountCheckAuthLoggedIn>
    <MyAccountNotificationsGrid />
  </MyAccountCheckAuthLoggedIn>
</template>
<script setup lang="ts">
const { url } = useDomainHost()
const { t } = useI18n()

useHead({
  title: t('notifications'),
  link: [useSeoCanonical(url + '/my-account/notifications')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account'),
      useSeoBreadcrumbItem(
        3,
        t('notifications'),
        url + '/my-account/notifications'
      )
    ])
  ]
})
</script>
