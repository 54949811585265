<template>
  <div>
    <RikazGrid name="notification" :items="notifications">
      <template #card="{ forKey, data }">
        <MyAccountNotificationsCard
          :key="`notification-grid-item-${forKey}`"
          class="transition-all duration-500 transform hover:scale-105"
          :notification="(data as UserNotification)"
        />
      </template>
    </RikazGrid>
    <ClientOnly>
      <MevcutInfiniteScroll
        class="my-5"
        :infinite-id="infiniteId"
        @load-more="loadMore"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import getAllRoutes from '~/composables/AppApiRoutes'
import { useNotificationStore } from '~~/store/notifications'
const props = defineProps({
  itemsPerPage: { type: Number, default: 10 },
  fetchRoute: { type: String, default: getAllRoutes().notificationsRoutes.getNotificationsByServer },
  fetchParams: { type: Object, default: () => {} },
  withQueryParams: { type: Boolean, default: false }
})

const notifications = ref<UserNotification[]>([])
const toast = useToast()
const route = useRoute()
const store = useNotificationStore()
const { t } = useI18n()

const { infiniteId, scrollFetch, loadMore, reset } = useInfiniteScroll(
  0,
  props.itemsPerPage,
  fetchNotifications,
  appendNotifications,
  clearNotifications
)

await scrollFetch()

if (props.withQueryParams) {
  watch(
    () => route.query,
    () => reset()
  )
}

async function fetchNotifications(state: ScrollState) {
  let queryParams = {}
  if (props.withQueryParams) {
    queryParams = { ...route.query }
  }

  try {
    const { data, error } = await useBasicFetch<ApiResponse<[]>>(props.fetchRoute, {
      query: {
        offset: `${state.offset}`,
        limit: `${state.limit}`,
        ...queryParams,
        ...props.fetchParams
      }
    })

    if (error?.value) {
      throw error.value
    }

    const unread = notifications.value.filter(
      (el) => !(el as UserNotification).read_at
    )

    store.setUnRead(unread.length)

    return data.value
  } catch (error) {
    if (process.client) {
      toast.error(t('fetch_failed', { data: t('notifications') }), { timeout: 1000 })
    }

    if ((error as any)?.data?.data) {
      return (error as any).data as ApiResponse<[]>
    } else {
      return {
        data: [],
        message: t('fetch_failed', { data: t('notifications') })
      } as ApiResponse<[]>
    }
  }
}

function appendNotifications(data: []) {
  notifications.value = [...notifications.value, ...data]
}

function clearNotifications() {
  notifications.value = []
}
</script>
